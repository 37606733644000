import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import MainHeader from './MainHeader';
import FixedCharacterWithBubble from './FixedCharacterWithBubble';

const MainLayout = () => {
  return (
    <>
      <MainHeader />
      <Outlet />
      {/* <FixedCharacterWithBubble /> */}
      <Footer />
    </>
  );
};

export default MainLayout;
